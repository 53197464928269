import React from 'react';
import './Skills.css';
import { Container, Row, Col } from 'react-bootstrap';

export default function Skills() {
  return (
    <Container id='skills' className='scp'>
      <Row>
        <Col className='skills mb-3'>
          <p className='lime miniTitle'>Skills</p>

          <Row className='skill-row'>
            <Col className='skill-col' xs={6} lg={3}>
              <ul>
                <li>C# <img alt='C# logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/csharp/csharp-plain.svg" /></li>
                <li>HTML <img alt='HTML logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-plain-wordmark.svg" /></li>
                <li>CSS <img alt='CSS logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-plain-wordmark.svg" /></li>
                </ul>
            </Col>
            <Col className='skill-col' xs={6} lg={3}>
              <ul>
                <li>JavaScript <img alt='JavaScript logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-plain.svg" /></li>
                <li>TypeScript <img alt='TypeScript logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/typescript/typescript-plain.svg" /></li>
                <li>React <img alt='React logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original.svg" /></li>
                </ul>
            </Col>
            <Col className='skill-col' xs={6} lg={3}>
              <ul>
                <li>Next.js <img alt='Next.js logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nextjs/nextjs-original.svg" /></li>
                <li>Angular <img alt='Angular logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/angularjs/angularjs-plain.svg" /></li>
                <li>Dotnet <img alt='Visual Studio Code logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/dot-net/dot-net-original.svg" /></li>
              </ul>
            </Col>
            <Col className='skill-col' xs={6} lg={3}>
              <ul>
                <li>Azure <img alt='Azure logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/azure/azure-original.svg" /></li>
                <li>Figma <img alt='Figma logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg" /></li>
                <li>Jira <img alt='Jira logo icon' className='icons' src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/jira/jira-original-wordmark.svg" /></li>
              </ul>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
