import React from 'react';
import './Projects.css';
import { Container, Row, Col } from 'react-bootstrap';

export default function Projects() {
  const radd = require('../../Assets/radd.png');
  const ihub = require('../../Assets/ihub.png');
  const monkeys = require('../../Assets/12monkeys.png');
  const lms = require('../../Assets/lms.png');
  const pokedex = require('../../Assets/pokedex.png');

  return (
    <Container id='projects' className='mt-5'>
      <p className='lime miniTitle'>Projects</p>

      <Container className="scp">
        <Row>
        <Col className='d-flex justify-content-center' xs={12} md={6} xl={4}>
            <div className='card card3'>
              <div className='img-box mx-3'>
                <img src={monkeys} alt='A project in development for 12 Monkeys Tattoos.' />
              </div>
              <div className='content'>
                <h3>12 Monkeys</h3>
                <p>
                  Orchestrated the complete redesign and development of a website for a local small business. Front end created in Next.js, Typescript. Using third-party API and SDK.
                </p>
                <Row>
                <Col className='col-5'>
                    <a href="https://www.12monkeystattoos.com/" target='_blank' rel='noreferrer'>Site</a>
                  </Col>
                  <Col className='col-7'>
                    <a href="https://github.com/MJGowan/12monkeys" target='_blank' rel='noreferrer'>Repository</a>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col className='d-flex justify-content-center' xs={12} md={6} xl={4}>
            <div class="card card2">
              <div class="img-box mx-3">
                <img src={ihub} alt='A section of iHub, displaying a carousel that I developed during my CodeStack Academy internship.' />
              </div>
              <div class="content">
                <h3>iHub</h3>
                <p>
                  Restructured a project under SJCOEs CodeStack Academy, adding responsiveness and reusable components. Worked with React, Typescript, HTML, CSS and JavaScript.
                </p>
                <Row>
                  <Col className='col-12'>
                    <a href="https://www.ihubsj.org" target='_blank' rel='noreferrer'>Site</a>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col className='d-flex justify-content-center' sm={12} md={6} xl={4}>
            <div className="card card1">
              <div class="img-box mx-3">
                <img src={radd} alt='A section of the website I developed for RaddxCap Investments' />
              </div>
              <div class="content">
                <h3>Radd Capital</h3>
                <p>
                  Conceptualized and developed a dynamic landing page for a Las Vegas investment group. Developed in React in conjunction with TypeScript, HTML, CSS and JavaScript.
                </p>
                <Row>
                  <Col className='col-5'>
                    <a href="https://raddxcap.vercel.app" target='_blank' rel='noreferrer'>Site</a>
                  </Col>
                  <Col className='col-7'>
                    <a href="https://github.com/MJGowan/radd" target='_blank' rel='noreferrer'>Repository</a>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col className='d-flex justify-content-center' sm={12} md={6} xl={4}>
            <div className="card card1">
              <div class="img-box mx-3">
                <img src={lms} alt='A section of the website used by CodeStack Academy students in the 2023-24 Night Class' />
              </div>
              <div class="content">
                <h3>CodeStack Academy</h3>
                <h5>2023-24 Classes</h5>
                <p>
                  Built curriculum interface using the Moodle CMS platform for software engineering program. Learning management system used by San Joaquin County Office of Educations program, CodeStack Academy. 
                </p>
              </div>
            </div>
          </Col>
          <Col className='d-flex justify-content-center' sm={12} md={6} xl={4}>
            <div className="card card2">
              <div class="img-box mx-3">
                <img src={pokedex} alt='A mock pokedex website created by Madeline Gowan' />
              </div>
              <div class="content">
                <h3>Pokedex</h3>
                <p>
                Displaying data from a third-party API to create an easy to use interface for a mock Pokedex. Created in JavaScript, HTML and CSS.
                </p>
                <Row>
                <Col className='col-5'>
                    <a href="https://mjgowan.github.io/pokemon/" target='_blank' rel='noreferrer'>Site</a>
                  </Col>
                  <Col className='col-7'>
                    <a href="https://github.com/MJGowan/pokemon" target='_blank' rel='noreferrer'>Repository</a>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

    </Container>
  )
}


