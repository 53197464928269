import React from 'react';
import './Navbar.css';
import { Container, Row, Col, Nav, Navbar } from 'react-bootstrap';

export default function Navbars() {
    const logoLight = require('../../Assets/transp-lop-white.png');

    return (
        <div id='nav'>
            <Navbar collapseOnSelect expand="lg" data-bs-theme="dark" className="navbar scp px-3">
                <Navbar.Brand href="#lop"> <img src={logoLight} className='lop' alt='Logo of a lop rabbit head.' /> </Navbar.Brand>
                <Navbar.Toggle className='toggle' aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <a className='link-item mx-3 mt-3' target="_blank" rel="noreferrer" href={require('../../Documents/Madeline Gowan.pdf')}><p>Resume</p></a>
                        <a className='link-item mx-3 mt-3' target="_blank" rel="noreferrer" href="https://github.com/MJGowan"><p>Github</p></a>
                        <a className='link-item mx-3 mt-3' target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/mjgowan/"><p>LinkedIn</p></a>
                        <a className='link-item mx-3 mt-3' target="_blank" rel="noreferrer" href="https://www.instagram.com/mjgowan.dev/"><p>Instagram</p></a>
                        {/* <a className='link-item mx-3 mt-3' target="_blank" rel="noreferrer" href="mailto: madelinejgowan@gmail.com"><p>Email</p></a> */}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}
