import React from 'react';
import './Footer.css';
import { Container, Row, Col } from 'react-bootstrap';

export default function Footers() {
    const logoLight = require('../../Assets/transp-lop-white.png');

    return (
        <div id='foot' className='scp px-3'>
            <Row>
                <Col className='d-flex justify-content-end'>
                    <img src={logoLight} className='lop' alt='Logo of a lop rabbit head.' />
                </Col>
            </Row>
        </div>
    )
}
