import React from 'react';
import './Lop.css';
import { Container, Row, Col } from 'react-bootstrap';

export default function Lop() {

    return (
        <div id='lop' className='self'>
            <div className='second-self'></div>
            <Container className='mb-5 scp'>
                <Row>
                    <Col className='lop-txt' xs={12} md={10} lg={6}  >
                        <h1 className='lime name'>Madeline Gowan</h1>
                        <p className='scp mt-3'>Frontend software developer, skilled in UI/UX design with an emphasis on accessibility and prototyping with Figma. In my free time I can be found gardening and caring for stray cats.</p>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
