import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Navbars from './components/Navbar/Navbar';
import Lop from './components/Lop/Lop';
import Skills from './components/Skills/Skills';
import Projects from './components/Projects/Projects';
import Footers from './components/Footer/Footer';

function App() {
  return (
    <div>
        <Navbars/>
        <Lop/>
        <Skills/>
        <Projects/>
        <Footers/>
    </div>
  );
}

export default App;
